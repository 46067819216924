export const contactInfo = {
    addressLine1: '637 E Bear Blvd',
    addressLine2: 'Springfield, Missouri-65806',
    email: 'ShusmoyChowdhury.Personal@Gmail.Com'
  
  }
  
  export const aboutMe: string =
    `My name is Shusmoy Chowdhury. I have always been passionate about building things—whether it’s software, research, or ideas that make life easier. My journey in technology began with a Bachelor of Science in Computer Science and Engineering from Bangladesh University of Engineering and Technology (BUET). After graduation, I stepped into the world of software development, joining BigGO Limited as a Full-Stack Developer, where I honed my skills in backend and frontend technologies.
  
  My desire to explore new challenges led me to the United States, where I pursued a Master’s in Computer Science at Missouri State University. There, I immersed myself in research on cloud computing, specifically focusing on load balancing and data center selection policies. My research contributions earned me the Outstanding Graduate Assistant Award, and later, the Distinguished Thesis Award. Additionally, my graduate thesis was recognized by the Midwestern Association of Graduate Schools (MAGS) with an honorable mention (1st runner-up) for the Distinguished Thesis Award—a historic achievement for Missouri State University, as it was the first time the university received this prestigious recognition.
  
  While completing my master's, I also worked as a Research Assistant, mentoring students, conducting workshops, and leading initiatives like the ROSE Grant 2023, where I educated high school teachers on IoT security vulnerabilities.
  
  Parallel to my academic journey, I gained extensive industry experience. At Girmairi LLC, I served as a Lead Software Engineer, managing a team of 10+ developers and working on cutting-edge software solutions. I specialized in full-stack development, AWS DevOps, and cloud automation, improving workflow efficiency by 70%. My work spanned across web, mobile, and cloud-based applications, with deployments on Google Play Store and the App Store.
  
  Currently, I am an Enterprise Software Engineer at Digital Monitoring Product, where I work on large-scale Java-based security applications used by banks and federal organizations like Bank of America, Capital One, and JPMorgan Chase & Co. I design and maintain software solutions that ensure security and automation in financial institutions.
  
  Beyond my professional journey, 2021 was the year that truly changed my life—not just in terms of my career but in the most beautiful way possible. A wonderful girl, Nisha Baul, came into my life as my lucky charm. She brought love, warmth, and an endless stream of new opportunities. Her presence turned my life around, filling it with positivity and success. And on July 16, 2024, we tied the knot, beginning a new chapter together. We are currently living in Missouri, USA, supporting each other’s dreams and ambitions while building a life filled with love and adventure.
  
  When I’m not coding, I love traveling, exploring different cultures, and indulging in diverse cuisines—now with my life partner by my side.`