
export const projectInfo=`I have worked on a diverse range of projects spanning cloud computing, machine learning, full-stack development, DevOps, cybersecurity, and mobile applications. My work includes optimizing cloud infrastructure, developing AI-driven recommendation systems, building secure authentication platforms, and designing scalable web and mobile applications. I have also implemented DevOps automation for streamlined deployments and worked on cybersecurity solutions to enhance system security. Recently, I have started exploring blockchain and cryptocurrency applications. My projects reflect my passion for innovation, problem-solving, and creating efficient, scalable solutions.`

export const projectCategory=[
    "All categories",
    "Research",
    "Web",
    "Automation",
    "Backend", 
    "Frontend",
    "Mobile App",
    "Boilerplate",
    "Dev-Ops"
];

export const researchProjects = [
    {
      title: 'Evolutionary DC Selection Policy',
      description: `Designed novel Machine Learning based data center(DC) selection policies in cloud-environment with Java which can outperform the existing DC selection policies and reduce the response time and data-processing time by 70% compared to the existing selection policies.`,
      subheader: 'Research',
      link: ""
      //`https://github.com/Shusmoy108/CloudAnalyst`,
    },
    {
      title: 'Movie Recommendation System with Clustering Algorithm',
      description: `Built a movie recommendation system using Apache Spark and the MovieLens dataset, employing clustering algorithms like K-Means, GMM, and LDA to suggest users approximately 80% similar movies based on users viewing history.`,
      subheader: 'Research',
      link: `https://github.com/Shusmoy108/Spark-Movie-Recommendations-System`,
    },
    {
      title: 'Content Suggestion with Topic Modeling',
      description: `Created a web-application with Python where user will be engaged to read blogs and continuously get suggestion based on the blogs user is reading using BERT, LDA and LSA and the suggestion system performs 70% better than other machine learning techniques.`,
      subheader: 'Research',
      link: `https://github.com/Shusmoy108/Topic-Modeling-Using-LDA`,
    },
    {
      title: 'Advanced Detection and Tracking Systems for Drone-Based Surveillance',
      description: `Designed novel Machine Learning based object detection with low cost drones with Python to efficiently detect and track objects within a specified area. Upon object detection, the drone autonomously follows the object, mapping a path to ensure it stays within the detection zone. Once the object exits the detection range, the drone returns to its original location, completing the task with minimal resource usage and high accuracy.`,
      subheader: 'Research',
      link: `https://github.com/Shusmoy108/Drone-Fly`,
    },
    {
      title: 'Pregnancy Delivery Mode Detection For Developing Countries',
      description: `Cesarean deliveries are rising in Bangladesh, driven by various factors. This study identifies key determinants using ultrasonography reports and historical data (age, weight, diabetes, etc.). We convert the data into a binary format for machine learning analysis to predict the mode of delivery accurately.`,
      subheader: 'Research',
      link: `https://github.com/Shusmoy108/Pregnancy-Mode-Detection`,
    },
    {
      title: 'TuitionHub',
      description: `In Bangladesh, students below university level rely heavily on tutors, but finding a suitable one is challenging. To address this, we developed an Android app connecting students, guardians, and teachers. Students and guardians can post tuition requests, while teachers can find opportunities based on their preferences. A two-way rating system helps assess the quality of both teachers and students. This project integrates human-computer interaction and software engineering to analyze user behavior, the education system, and regional trends. Additionally, UI/UX research was conducted to maximize user engagement.`,
      subheader: 'Research',
      link: `https://github.com/Shusmoy108/TutionHub`,
    },
    {
      title: 'Evolutionary Algorithms in Matlab',
      description: `This project includes various MATLAB implementations for optimization and problem-solving using evolutionary algorithms. It covers comparisons of evolutionary algorithms on benchmark functions, real-coded genetic algorithms with different selection policies, software effort estimation using Genetic Programming (GP) and Genetic Algorithms (GA), the Traveling Salesman Problem (TSP) solved with GA, and a neural network implementation with backpropagation and evolutionary algorithms like PSO, Grey Wolf Optimizer, and GA. These solutions demonstrate the application of evolutionary algorithms across different optimization and machine learning problems.`,
      subheader: 'Research',
      link: `https://github.com/Shusmoy108/Evolutionary-Algorithms`,
    }
  ];
  
  
  export const webProjects = [
    {
      title: 'ProTrack',
      description: `Developed a flexible project management system using Node.js and React.js, adaptable to various industries. Our analysis revealed that project structures vary across industries and even within departments of the same organization. Most existing solutions lack customization, limiting users' ability to design their workflows. Our software empowers users to create personalized project structures, which can later be used to assign tasks efficiently.`,
      subheader: 'Web',
      link: `https://github.com/Shusmoy108/ProTrack`,
    },
    {
      title: 'Blogged',
      description: `Developed a web-based blog writing system using Node.js and React.js, allowing users to write on various topics. Readers can comment, with authors having the ability to remove unwanted comments. Blogs can also receive support from readers. A mobile app version is available, making the platform accessible on the go. This system can serve as an online newspaper or a discussion hub.`,
      subheader: 'Web',
      link: `https://github.com/Shusmoy108/Blog`,
    },
    {
      title: 'Online Registration System',
      description: `Developed an online registration system for debating competitions using Node.js and React.js. Participants can register for events and receive their admit cards via email. The system ensures that each participant registers only once using their email ID.`,
      subheader: 'Web',
      link: `https://github.com/Shusmoy108/NDC-EnglishCarnival`,
    },
    {
      title: 'Google ReCAPTCHA',
      description: `Developed a Node.js and React-based application that integrates Google reCAPTCHA for enhanced security. This system prevents spam and automated submissions by requiring users to complete a CAPTCHA challenge before submitting forms. It provides a seamless and secure user experience while protecting the application from malicious activities.`,
      subheader: 'Web',
      link: `https://github.com/Shusmoy108/Node-React-Google-ReCAPTCHA`,
    }
  
  ];
  
  
  export const automationProjects = [
    {
      title: 'Facebook Marketplacelisting',
      description: `Developed a flexible project management system using Node.js and React.js, adaptable to various industries. Our analysis revealed that project structures vary across industries and even within departments of the same organization. Most existing solutions lack customization, limiting users' ability to design their workflows. Our software empowers users to create personalized project structures, which can later be used to assign tasks efficiently.`,
      subheader: 'Automation',
      link: ''
      //`https://github.com/Shusmoy108/Java-Facebook-Marketplacelisting`,
    },
    {
      title: 'Facebook Page Automation',
      description: `Developed a web-based blog writing system using Node.js and React.js, allowing users to write on various topics. Readers can comment, with authors having the ability to remove unwanted comments. Blogs can also receive support from readers. A mobile app version is available, making the platform accessible on the go. This system can serve as an online newspaper or a discussion hub..`,
      subheader: 'Automation',
      link: ''
      //`https://github.com/Shusmoy108/Java-Facebook-Page-Automation`,
    }
  ];
  
  export const backendProjects = [
    {
      title: 'Role Based Authentication',
      description: `Developed a role-based authentication system using Java Spring Boot, OAuth 2.0 and JWT, ensuring secure access control for users with different roles. The system manages user authentication and authorization, allowing administrators to define permissions and restrict access based on roles. This enhances security and flexibility for applications requiring multi-level user management.`,
      subheader: 'Backend',
      link: `https://github.com/Shusmoy108/Java-Role-Based-Authentication`,
    },
    {
      title: 'Mini Twitter Backend',
      description: `Developed a backend system for a mini Twitter-like application using Node.js. This project includes user authentication, the ability to post tweets, follow/unfollow users, and manage timelines. It supports functionalities like posting, liking, and deleting tweets, along with following and viewing tweets from followed users. The backend is built to handle user interactions and ensure efficient data management for a Twitter-like experience.`,
      subheader: 'Backend',
      link: `https://github.com/Shusmoy108/Mini-Twitter-Backend`,
    },
  ];
  
  export const frontendProjects = [
    {
      title: 'Facebook Page Messenger',
      description: `Developed a React-based application that integrates with Facebook Page Messenger, enabling seamless communication between businesses and their customers. The system allows users to send and receive messages directly through their Facebook pages, providing an efficient way to manage customer interactions.`,
      subheader: 'Frontend',
      link: `https://github.com/Shusmoy108/React-Facebook`,
    },
    {
      title: 'Stripe Payment UI',
      description: `Developed an online payment processing system using Stripe and React.js. This solution enables seamless and secure transactions for various applications. Users can make payments effortlessly, with Stripe handling authentication and transaction security. The system supports real-time payment verification and ensures a smooth checkout experience.`,
      subheader: 'Frontend',
      link: `https://github.com/Shusmoy108/Stripe-React-Payment`,
    },
    {
      title: 'Todo Dashboard',
      description: `Developed a Todo Dashboard application using React, providing users with a simple and efficient interface to manage tasks. The app allows users to add, edit, delete, and mark tasks as complete, offering a seamless and interactive experience. The dashboard also includes features for task organization and prioritization, making it an effective tool for personal task management.`,
      subheader: 'Frontend',
      link: `https://github.com/Shusmoy108/Todo-Dashboard`,
    }
  ];
  
  export const mobileProjects = [
    {
      title: 'Mini Twitter',
      description: `Developed a Mini Twitter App using Flutter, replicating core features of Twitter. Users can create 	and view posts (tweets), follow and unfollow other users, and interact with content. The app includes real-time updates and a simple, responsive UI for an engaging user experience. It serves as a prototype for building social networking platforms with basic functionalities like user interaction and content sharing.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/Mini-Twitter-App`,
    },
    {
      title: 'AirBnB Clone',
      description: `Developed an AirBnB clone using React Native, replicating core functionalities of the popular platform. Users can browse available listings, create and manage their own listings, book accommodations, and communicate with hosts. The platform features user authentication, secure payment integration, and responsive UI, providing a seamless experience for both guests and hosts. This project serves as a prototype for building rental and booking platforms.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/AirBnB-Clone`,
    },
    {
      title: 'Class Attendant',
      description: `Developed a Class Attendant system using Flutter that allows teachers to manage student attendance and generate PDF attendance sheets. The app enables teachers to mark attendance for each class session and export the attendance records as a PDF document. This system provides a simple and efficient solution for tracking attendance and generating reports, all within a user-friendly mobile application.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/Class-Attendant`,
    },
    {
      title: 'Blood Donor',
      description: `developed a Blood Donor application using Flutter that connects blood donors with individuals in need. The app allows users to register as blood donors, search for nearby donation requests, and offer assistance. It also helps users track donation history and receive notifications for urgent donation needs. This platform aims to streamline the blood donation process and make it easier for donors to contribute to saving lives.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/Blood-Donor`,
    },
    {
      title: 'Dual Sim App',
      description: `Developed a Dual-SIM management system using Flutter that helps users optimize their mobile data usage by selecting the SIM card with the lowest cost for each call or data session. The app intelligently monitors the available SIMs and automatically chooses the most cost-effective option, providing users with an efficient way to manage their dual-SIM devices and reduce communication expenses.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/DualSim`,
    },
    {
      title: 'E-commerce App',
      description: `Developed an E-commerce home application using Flutter, providing a user-friendly interface for browsing products, managing shopping carts, and processing orders. The app features product categories, product search functionality, and a responsive design for a seamless shopping experience across devices. It integrates essential e-commerce features to offer a complete online shopping solution`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/E-Commerce-Home-App`,
    },
    {
      title: 'Blogged App',
      description: `Developed a blog application using modern web technologies that allows users to create, edit, and delete posts. The platform also supports features like user authentication, comments, and post categorization. Admins have control over managing posts and user interactions, while users can interact with content through likes and comments. This app provides a comprehensive solution for building a user-friendly blog platform with an intuitive interface and secure user management.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/Blog-App`,
    },
    {
      title: 'Component Gallery',
      description: `Developed a Flutter-based component gallery showcasing a variety of UI components and widgets for mobile application development. The project includes examples of various Flutter widgets, such as buttons, cards, lists, and navigation components, to demonstrate their functionality and usage. It serves as a reference for developers to quickly explore and implement common Flutter UI elements in their own projects.`,
      subheader: 'Mobile App',
      link: `https://github.com/Shusmoy108/Flutter-Component-Gallery`,
    }
  ];
  
  export const boilerplateProjects = [
    {
      title: 'Node Typescript Boilerplate',
      description: `Developed a Node.js and TypeScript boilerplate project to streamline the setup of scalable and maintainable backend applications. This template includes essential configurations, such as TypeScript integration, Express.js setup, and a structured project architecture. It serves as a starting point for building robust applications with clean and modular code, supporting future growth and easy maintenance.`,
      subheader: 'Boilerplate',
      link: `https://github.com/Shusmoy108/Node-Typescript-Boilerplate`,
    },
    {
      title: 'Flutter Node Boilerplate',
      description: `Developed a boilerplate application using Flutter and Node.js, providing a foundational framework for building cross-platform mobile applications with a Node.js backend. This project includes essential features such as user authentication, API integration, and real-time communication, allowing developers to quickly start and scale mobile applications. The combination of Flutter for the frontend and Node.js for the backend ensures a seamless, efficient development process for building modern mobile apps.`,
      subheader: 'Boilerplate',
      link: `https://github.com/Shusmoy108/Flutter-Nodejs-BoilerPlate`,
    },
    {
      title: 'Django MySQL Boilerplate',
      description: `Developed a Django-based boilerplate application with MySQL integration, providing a foundational setup for web development projects. This repository includes the essential configurations for building scalable and secure web applications using Django, with MySQL as the database backend. It includes features such as user authentication, session management, and API integration, serving as a starting point for rapid development of robust web applications.`,
      subheader: 'Boilerplate',
      link: `https://github.com/Shusmoy108/Django_MySQL_Boiler_Plate`,
    },
    {
      title: 'Node MongoDB React Boilerplate',
      description: `Developed a boilerplate project that integrates Node.js, React.js, and MongoDB to provide a scalable and efficient foundation for full-stack applications. This template includes a backend built with Node.js and Express, a frontend powered by React.js, and MongoDB for data storage. It is designed to streamline the development process, offering essential features like user authentication, API routes, and database connection, enabling developers to quickly build and deploy modern web applications.`,
      subheader: 'Boilerplate',
      link: `https://github.com/Shusmoy108/NodeReactBoiolerpllate`,
    }
  ];
  
  export const devopsProjects = [
    {
      title: 'Forward Proxy Setup',
      description: `Developed an Apache Forward Proxy setup for managing network traffic and enhancing security using DevOps practices. This repository provides a comprehensive guide and configuration files for setting up an Apache server as a forward proxy. The setup allows for routing client requests through the proxy, enabling controlled access to external resources, traffic filtering, and logging. The implementation is designed for scalability and ease of maintenance, aligning with DevOps principles for continuous integration and delivery in a network management environment.`,
      subheader: 'Dev-Ops',
      link: `https://github.com/Shusmoy108/Apache-Forward-Proxy-Setup`,
    }
  ]
  