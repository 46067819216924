
import CloudIcon from '@mui/icons-material/Cloud';
import SecurityIcon from '@mui/icons-material/Security';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import MedicationIcon from '@mui/icons-material/Medication';

export const experienceInfo=`          Experienced Software Engineer with a track record of delivering
          cutting-edge web and mobile applications to prestigious clients over
          4+ years. Demonstrated expertise in adeptly managing software
          development teams through strategic planning, consulting, and
          efficient work allocation. Proficient in DevOps operations,
          successfully implementing automation in development processes. Excited
          to bring my skills and achievements to a dynamic team of innovators.`
export const experience = [
  {
    icon: <CloudIcon />,
    title: 'Digital Monitoring Product (DMP)',
    description: 'Enterprise Software Engineer (January 8, 2024- Present)',
    details: `–Working in a large Java Based Application named Entre widely used in federal and state organizations for communication with their panels for maintaining security.

–Working in a large Java Based Automation Application named SCS-VR widely used in banks to communicate with central stations to take necessary steps during fire and burglary alarms.

–Working with different banks and financial institutions like Bank of America, Capital One, Arvest Bank, and JPMorgan Chase & Co to enhance their security with more than 6000 panels.

–Designs, writes, maintains, tests, and debugs software that is modular, maintainable, and easy to understand.
`,
  },
  // {
  //   icon: <EdgesensorHighRoundedIcon />,
  //   title: 'Unmanned Aerial Vehicle (UAV)',
  //   description:
  //     'This item could provide information about the mobile app version of the product.',
  //   imageLight: `url("${dronfly}")`,
  //   imageDark: `url("${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'}/static/images/templates/templates-images/mobile-dark.png")`,
  // },
  {
    icon: <MedicationIcon />,
    title: 'Girmairi',
    description: 'Software Engineer (September 1, 2021- Present)',
    details: `–Led the development team of 10+ members, providing guidance and direction to deliver most of the products successfully before the deadline.

    –Working as a seasoned full-stack developer by the company to architect and develop cutting-edge software solutions for web and mobile applications with Java, Javascript, and React Native.

    –Spearheaded the design and implementation of an AWS streamlined DevOps operation(Codepipeline, Codebuild, Codedeploy, Lambda, ECS, EC2, S3, IAM, RDS), deployed mobile application in google playstore and appstore, enhancing automation for the entire development team and increasing work efficiency by 70%.
    
    –Enhanced product quality by 85% with employing Google Analytics, Google ReCAPTCHA, CHATGPT, ZOHO and advanced SEO strategies.`,
  },
  {
    icon: <SecurityIcon />,
    title: 'Missouri State University',
    description: 'Graduate Assistant (January 17, 2022 - December 15, 2023)',
    imageLight: `url("${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'
      }/static/images/templates/templates-images/devices-light.png")`,
    imageDark: `url("${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'
      }/static/images/templates/templates-images/devices-dark.png")`,
    details: `
    –Courses Taught: Introduction to C++ Programming, Data Structure, Computational Thinking, Operating Systems.

    –Demonstrated expertise by consistently evaluating examination papers, facilitating laboratory sessions, assessing projects and orchestrating weekly programming workshops.

    –Orchestrated the mentor-ship of four senior student groups, providing invaluable assistance in project planning, database design, and delivering constructive feedback for empowering students to achieve outstanding project outcomes and receive accolades from industry professionals.

–Led the ROSE Grant 2023 program, enlightening ten high school teachers about the security vulnerabilities linked to IoT devices.
`,
  },
  // {
  //   icon: <SchoolIcon />,
  //   title: 'K-12 Education Learning',
  //   description:
  //     'This item could provide a snapshot of the most important metrics or data points related to the product.',
  //   imageLight: `url("${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'}/static/images/templates/templates-images/dash-light.png")`,
  //   imageDark: `url("${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'}/static/images/templates/templates-images/dash-dark.png")`,
  // },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Global Brand Pvt Ltd',
    description:
      'Contractual Mobile App Developer (June 1, 2021 - September 1, 2021)',
    details: `–Organized the development of the company's mobile application with react-native, ensuring its 100% successful implementation and functionality in both iOS and android.

    –Developed and launched the company's one of the most comprehensive mobile applications for ecommerce, focusing on computer accessories, showcasing technical expertise and innovation.

    –Successfully orchestrated the application's launch on both Google Play Store and iOS App Store, ensuring 100% smooth rollout process.`,
  },
  //,

  // {
  //   icon: <DevicesRoundedIcon />,
  //   title: 'BigGO Limited',
  //   description:
  //     'Full Stack Developer (June 1, 2021 - 1 September 1, 2021)',
  //   imageLight: `url("${
  //     process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'
  //   }/static/images/templates/templates-images/devices-light.png")`,
  //   imageDark: `url("${
  //     process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'
  //   }/static/images/templates/templates-images/devices-dark.png")`,
  //   details: `–Organized the development of the company's mobile application with react-native, ensuring its 100% successful implementation and functionality in both iOS and android.

  //   –Developed and launched the company's one of the most comprehensive mobile applications for ecommerce, focusing on computer accessories, showcasing technical expertise and innovation.

  //   –Successfully orchestrated the application's launch on both Google Play Store and iOS App Store, ensuring 100% smooth rollout process.`
  // },
];