export const languages = [
  { logo: 'skills/language/js.svg', name: 'JavaScript' },
  { logo: 'skills/language/ts.svg', name: 'TypeScript' },
  { logo: 'skills/language/c.svg', name: 'C' },
  { logo: 'skills/language/cplus.svg', name: 'C++' },
  { logo: 'skills/language/csharp.svg', name: 'C#' },
  { logo: 'skills/language/java.svg', name: 'Java' },
  { logo: 'skills/language/python.svg', name: 'Python' },
  { logo: 'skills/language/html.svg', name: 'HTML' },
  { logo: 'skills/language/css.svg', name: 'CSS' },
  { logo: 'skills/language/tailwind.svg', name: 'Tailwind CSS' },
  { logo: 'skills/language/dart.svg', name: 'Dart' },
  { logo: 'skills/language/scala.svg', name: 'Scala' },
  { logo: 'skills/language/latex.svg', name: 'LaTeX' },
];

// Similarly update frameworks array
export const frameworks = [
  { logo: 'skills/framework/nodejsDark.svg', name: 'Node.js' },
  { logo: 'skills/framework/react.svg', name: 'React Js' },
  { logo: 'skills/framework/django.svg', name: 'Django' },
  { logo: 'skills/framework/flutter.svg', name: 'Flutter' },
  { logo: 'skills/framework/android.svg', name: 'Android' },
  { logo: 'skills/framework/spring.svg', name: 'Spring' },
  { logo: 'skills/framework/springboot.svg', name: 'Spring Boot' },
];

// Similarly update frameworks array
export const database = [
  { logo: 'skills/database/mysql.svg', name: 'MYSQL' },
  { logo: 'skills/database/oracle.svg', name: 'Oracle' },
  { logo: 'skills/database/mongodb.svg', name: 'MongoDB' },
  { logo: 'skills/database/postgresql.svg', name: 'Postgresql' },
  { logo: 'skills/database/firebase.svg', name: 'Firebase' },
];

// DevOps tools and platforms array
export const devops = [
  { logo: 'skills/devops/jenkins.svg', name: 'Jenkins' },
  { logo: 'skills/devops/bitbucket.svg', name: 'Bitbucket' },
  { logo: 'skills/devops/cloudflare.svg', name: 'Cloudflare' },
  { logo: 'skills/devops/nginx.svg', name: 'Nginx' },
  { logo: 'skills/devops/tomcat.svg', name: 'Tomcat' },
  { logo: 'skills/devops/lambda.svg', name: 'AWS Lambda' },
  { logo: 'skills/devops/s3.svg', name: 'AWS S3' },
  { logo: 'skills/devops/route53.svg', name: 'AWS Route 53' },
  { logo: 'skills/devops/cloudfront.svg', name: 'AWS CloudFront' },
  { logo: 'skills/devops/elasticbeanstalk.svg', name: 'AWS Elastic Beanstalk' },
  { logo: 'skills/devops/codedeploy.svg', name: 'AWS CodeDeploy' },
  { logo: 'skills/devops/iam.svg', name: 'AWS IAM' },
  { logo: 'skills/devops/sns.svg', name: 'AWS SNS' },
  { logo: 'skills/devops/waf.svg', name: 'AWS WAF' },
];

