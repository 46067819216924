
export const papers: any = [
    {
      cite: `Chowdhury, Shusmoy, Ajay Katangur, and Alaa Sheta. "Optimization of datacenter selection through a genetic algorithm-driven service broker policy." Journal of Cloud Computing 13, no. 1 (2024): 1-36.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Chowdhury, Shusmoy, Jeenat Sultana, and Kyanu Mong Marmae. "Sourojan: A Low Cost and Profitable Solar Boat for Coastal Tourist Areas." In 2024 IEEE Region 10 Symposium (TENSYMP), pp. 1-6. IEEE, 2024.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Sultana, Jeenat, Shusmoy Chowdhury, Moynul Hasan Tushar, and Md Hemayet Uddin. "Smart Door Unlock System with Android App." In 2024 IEEE Region 10 Symposium (TENSYMP), pp. 1-6. IEEE, 2024.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Katangur, Ajay, and Shusmoy Chowdhury. "DEThresh: Enhancing Cloud Computing Performance with Differential Evolution-Driven Datacenter Selection and Threshold-based Load Balancing Optimization." (2024).`,
      publish: true,
      link: ``,
    },
    {
      cite: `Chowdhury, Shusmoy, and Ajay Katangur. "Optimization of Datacenter Selection Policy in Cloud Computing using Differential Evolution Algorithm." In 2023 IEEE Cloud Summit, pp. 33-40. IEEE, 2023.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Chowdhury, Shusmoy, and Ajay Katangur. "Optimal Datacenter Selection for Cloud Services Using Swarm Intelligence." In 2023 26th International Conference on Computer Supported Cooperative Work in Design (CSCWD), pp. 1366-1371. IEEE, 2023.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Chowdhury, Shusmoy, Ajay Katangur, Alaa Sheta, Nagesh Rao Psayadala, and Siming Liu. "Genetic Algorithm Based Service Broker Policy to find Optimal Datacenters in Cloud Services." In 2023 8th International Conference on Cloud Computing and Big Data Analytics (ICCCBDA), pp. 270-278. IEEE, 2023.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Chowdhury, Shusmoy. "Optimal Cloud Datacenter Selection Using Evolutionary Algorithms." (2023).`,
      publish: true,
      link: ``,
    },
    {
      cite: `Chowdhury, Shusmoy, and Ajay Katangur. "Threshold based load balancing algorithm in cloud computing." In 2022 IEEE international conference on joint cloud computing (JCC), pp. 23-28. IEEE, 2022.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Alam, Mohammad Jahangir, Dhiman Sarma, Rana Joyti Chakma, Md Mynoddin, Shusmoy Chowdhury, Jia Raihan, and Sohrab Hossain. "A Smart Security Solution to Prevent any Mischief with the Vehicles." In 2021 IEEE Region 10 Symposium (TENSYMP), pp. 1-5. IEEE, 2021.`,
      publish: true,
      link: ``,
    },
    {
      cite: `Alam, Mohammad Jahangir, Tanjia Chowdhury, Sohrab Hossain, Shusmoy Chowdhury, and Tanmoy Das. "Child tracking and hidden activities observation system through mobile app." Indonesian Journal of Electrical Engineering and Computer Science 22, no. 3 (2021): 1659-1666.`,
      publish: true,
      link: ``,
    },
  ];
  
  